$('select.select-custom').each(function(index, el){
	let name 	= $(this).attr('name');
	let id 		= $(this).attr('id');

	let defaultText 	= $(this).find('option[value=""]').html() || 'Selecione';
	let options 		= '';

	let template 		= `
		<div class="select-custom">
			<input class="form-control output" placeholder="{{defaultText}}" disabled>

			<div class="drop-options">
				<input type="text" name="filtro" data-target="#options-list-${index}" id="${id}"/>

				<ul id="options-list-${index}">
					{{options}}
				</ul>
			</div>
		</div>
	`;

	$(this).find('option').each(function(i,e){
		if($(this).val() != ''){

			let text 	= $(this).html();
			let val 	= $(this).attr('value');

			let option 	= `
				<li>
					<input type="radio" id="${id}-option-${i}" name="${name}" value="${val}"/>
					<label for="${id}-option-${i}">${text}</label>
				</li>
			`;

			options += option;
		}
	});

	template = template.replace(/{{defaultText}}/g, defaultText);
	template = template.replace(/{{options}}/g, options);

	$(this).after(template);
	$(this).remove();
});


$('.select-custom input[name="filtro"]').focus(function(){
	$(this).parents('.select-custom').addClass('open');
});

$('.select-custom input[name="filtro"]').keyup(function(){
	function normalizeString(string){
		return string.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
	}

	let value 	= $(this).val();
	let filtro 	= normalizeString(value);
	let target 	= $(this).data('target');

	$(target+' label').each(function(){
		let text 	= $(this).text();
		let option 	= normalizeString(text);
		let parent 	= $(this).parent('li');

		if(option.indexOf(filtro) < 0){
			parent.hide();
		}else{
			parent.removeAttr('style');
		}
	});
});

$(document).click(function(){
	$('.select-custom').removeClass('open');
	$('.select-custom input').blur();
});

$('.select-custom').click(function(e){
	e.stopPropagation();

	$(this).addClass('open');
	$(this).find('input[name="filtro"]').focus();
})

$('.select-custom input[type="radio"]').on('change', function(){
	let output 	= $(this).parents('.select-custom').find('.output');
	let text 	= $(this).siblings('label').text();
	let selectCustom = output.parents('.select-custom');

	output.text(text)
	output.val(text).change();
	output.parents('.md-form').addClass('active');
	selectCustom.removeClass('open');

	selectCustom.find('input[name="filtro"]').val('').change();

	selectCustom.find('li').removeAttr('style');
});

