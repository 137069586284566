// const LazyBgObserver = new IntersectionObserver(entries=> {
// 	entries.forEach(entry=> {

// 		if(entry.isIntersecting) {
// 			let animationClasses = entry.target.dataset.animate || '';

// 			animationClasses = animationClasses.split(' ');

// 			animationClasses.forEach(classe => entry.target.classList.add(classe));

// 			LazyBgObserver.unobserve(entry.target);
// 		}

// 	});

// }, {
// 	rootMargin: '200px 200px 200px 200px'
// });

// const lazyBg = document.querySelectorAll('[data-lazy-bg]');
// lazyBg.forEach(el => LazyBgObserver.observe(el));


var LazyBg = (function(){
	'use restrict';
	var isLazedBg = function(){
		$('[data-lazy-bg]').each(function(index, el) {
			if(el.getBoundingClientRect().top < window.innerHeight + 200){
				$(this).css('background-image', 'url('+$(this).attr('data-lazy-bg')+')');
				$(this).removeAttr('data-lazy-bg')
			}
		});
	};
	var jaLazyimageBg = false;

	setTimeout(isLazedBg(),200);

	$(window).scroll(function(event) {
		if(jaLazyimageBg) return;

		setTimeout(function(){
			jaLazyimageBg = false;
		},100);

		isLazedBg();
	});
})();